<template>
  <el-menu
    default-active="2"
    class="el-menu-vertical-demo">
    <el-menu-item index="2" @click="navigateTo('/')">商品状态查询</el-menu-item>
    <el-menu-item index="3" @click="navigateTo('/douyinurl')">抖音地址转换</el-menu-item>
  </el-menu>
</template>
  
  <script>
  export default {
    methods: {
    navigateTo(path) {
      if (this.$route.path !== path) {
        this.$router.push(path).catch(err => {
          // 处理导航错误，例如导航到相同路径时
          if (err.name !== 'NavigationDuplicated') {
            console.error(err);
          }
        });
      }
    }
  }
  }
  </script>
  
  <style>

  /* 样式代码 */
  </style>