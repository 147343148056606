<template>
  <div id="app">
    <el-container>
    <!-- 左侧固定导航 -->
    <el-aside width="200px;" class="nav">
      <!-- 导航内容 -->
      <AppNavigation />
    </el-aside>
    <!-- 右侧内容区 -->
    <el-container>
      <el-main>
        <!-- 右侧内容 -->
        <router-view />
      </el-main>
    </el-container>
  </el-container>
  </div>
</template>

<script>
import AppNavigation from './components/AppNavigation.vue'
export default {
  name: 'App',
  components: {
    AppNavigation
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
.nav{
  background: #f3f4f6;
  width: 200px;
  height: 100vh;
  padding-top: 50px;
}
body {
  margin: 0;
}
</style>
