import Vue from 'vue'
import ElementUI from 'element-ui';
import App from './App.vue'
import axios from "axios"
import router from './router'
import 'element-ui/lib/theme-chalk/index.css';
import VueClipBoard from 'vue-clipboard2'

Vue.use(ElementUI);
Vue.use(VueClipBoard)
// Vue.use(VueRouter)
Vue.prototype.$axios = axios;
Vue.config.productionTip = false
axios.defaults.baseURL = 'httsp://douyin.com'

new Vue({
  axios,
  el: '#app',
  router,
  render: h => h(App),
}).$mount('#app')
