<template>
    <el-row :gutter="20">

        <!-- <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
            title="读取进度"
            :visible.sync="dialogVisible"
            width="30%"
            :before-close="handleClose">

            <div>总计商品数量:{{ num }}</div>
            <div style="margin-bottom: 30px;">当前完成数量:{{ acnum }}</div>

            <el-progress type="circle" :percentage="percentage" ></el-progress>
      </el-dialog> -->

        <el-col :span="12">
            <!-- <el-progress :percentage="percentage" color="#409eff"></el-progress> -->
            <!-- <el-progress type="circle" :percentage="percentage" status="success"></el-progress> -->
            <!-- <el-progress :text-inside="true" :stroke-width="10" :percentage="percentage" status="exception"
                style="width: 100%; margin-bottom: 20px;"></el-progress> -->
            <el-button type="primary" @click="checkProduct" style="width: 100%; margin-bottom: 20px;">{{ '商品检测(共' + num
        + '个商品)' }}</el-button>


            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 9999 }" placeholder="请输入内容"
                v-model="productIdList" @input="calculateLines">
            </el-input>
        </el-col>
        <el-col :span="12">
            <el-table v-loading="loading" stripe border :data="productIdListres" style="width: 100%">
                <el-table-column>
                    <template slot="header" slot-scope="scope">
                        <el-row>
                            <el-col :span="12">
                                <div>状态</div>
                            </el-col>
                            <el-col :span="12">
                                <el-button size="small" @click="copyColumn(scope.$index)">复制整列数据</el-button>
                            </el-col>
                        </el-row>
                    </template>
                    <template slot-scope="scope">
                        {{ scope.row }}
                    </template>
                </el-table-column>
            </el-table>

        </el-col>
    </el-row>
</template>

<script>

export default {
    // 组件代码
    name: "ProductStatus",
    data() {
        return {
            loading: false,
            productIdList: "",
            productIdListres: [],
            percentage: 0,
            num: 0,
            acnum:0,
            dialogVisible:false
        }
    },
    methods: {
        copyColumn(index, key) {
            const selectedColumnData = []; 
            for (let i = 0; i < this.productIdListres.length; i++) {
                selectedColumnData.push(this.productIdListres[i]);
            }
            const copyText = selectedColumnData.join('\n');
            navigator.clipboard.writeText(copyText).then(() => {
                console.log('复制成功');
            }).catch((error) => {
                console.log('复制失败', error);
            });
        },
        calculateLines() {
            const content = this.productIdList;
            const lines = content.split('\n').length;
            this.num = lines;
        },
        async checkProduct() {
            this.productIdListres = [];
            if (!this.productIdList.length) {
                this.$message.error('商品ID不能为空');
                return
            }
            this.loading = true
            document.title = "开始检测商品"
            this.dialogVisible = true

            const lines = this.productIdList.split('\n');
            let isfall = false;


            this.$axios({
                    method: "POST",
                    url: 'https://douyin.dgrlm.com/api/dy/douyinurl',
                    data: lines
                }).then(res => {
                    // resolve(res)
                    this.productIdListres =res.data.data
                    // if (!res) {
                    //     console.log("商品是下架商品");
                    //     resolve()
                    // } else {
                    //     if (res.inStock && res.presellType == 1) {
                    //         console.log("商品是预售商品");
                    //     }

                    //     if (res.inStock && res.presellType == 0) {
                    //         console.log("商品是现货商品");
                    //     }

                    //     if (!res.inStock && res.presellType == 1) {
                    //         console.log("商品是无货");
                    //     }
                    //     resolve()

                    // }

                }).catch(err => {
                    console.log(err);
                    reject()
                })

            // for (let index = 0; index < lines.length; index++) {
            //     const element = lines[index];
            //     await this.getGoodsDetails(element).then(res => {


            //         if(res.data.code == 200){
            //             this.productIdListres.push(res.data.data)
            //             var regex = new RegExp(element+'\n|\n'+element+'|'+element, 'gm');
            //             this.productIdList = this.productIdList.replace(regex,"")
            //         }else{
            //             this.productIdListres.push({ "status": "下架" });
            //         }
                  
            //     }).catch(err => {
            //         this.productIdListres.push({ "status": "下架" });
            //     })
                

            //     this.acnum = index+1
            //     this.percentage = Math.floor((index + 1) / lines.length * 100)
            //     document.title = "检测商品进度:" + this.percentage + '%'

            // }

            this.loading = false
            this.dialogVisible = false
            // this.$message({
            //     message: lines.length+'个商品已经全部读取完毕',
            //     type: 'success'
            // });

            this.$notify({
                title: '执行完毕',
                message: lines.length + '个商品已经全部读取完毕',
                position: 'bottom-left'
            });
            this.num = 0

        },

    }
}
</script>

<style>
/* 样式代码 */
</style>