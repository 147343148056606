import Vue from 'vue'
import Router  from 'vue-router'
import ProductStatus from '@/view/ProductStatus'
import douyinurl from '@/view/douyinurl'

Vue.use(Router)

const router = new Router({
  mode:"history",
  base:'/',
  routes: [
    {
      path: '/',
      name: 'ProductStatus',
      meta:{
        title:"商品检测"
      },
      component: ProductStatus
    },
    {
      path: '/douyinurl',
      name: 'douyinurl',
      meta:{
        title:"抖音地址转换"
      },
      component: douyinurl
    }
  ]
})


router.beforeEach((to, from, next) => {
  // 在路由导航之前执行的逻辑
  document.title = to.meta.title || '默认标题'; // 设置页面标题为目标路由的 meta 字段中的 title，如果没有设置则使用默认标题
  // 继续路由导航
  next();
});


export default router