<template>
    <el-row :gutter="20">

        <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
            title="读取进度"
            :visible.sync="dialogVisible"
            width="30%"
            :before-close="handleClose">

            <div>总计商品数量:{{ num }}</div>
            <div style="margin-bottom: 30px;">当前完成数量:{{ acnum }}</div>

            <el-progress type="circle" :percentage="percentage" ></el-progress>
      </el-dialog>


        <el-col :span="5">
            <!-- <el-progress :percentage="percentage" color="#409eff"></el-progress> -->
            <!-- <el-progress type="circle" :percentage="percentage" status="success"></el-progress> -->
            <!-- <el-progress :text-inside="true" :stroke-width="10" :percentage="percentage" status="exception"
                style="width: 100%; margin-bottom: 20px;"></el-progress> -->
            <el-button type="primary" @click="checkProduct" style="width: 100%; margin-bottom: 20px;">{{ '商品检测(共' + num
        + '个商品)' }}</el-button>


            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 9999 }" placeholder="请输入内容"
                v-model="productIdList" @input="calculateLines">

                
            </el-input>
        </el-col>
        <el-col :span="19">
            <el-table v-loading="loading" stripe border :data="productIdListres" style="width: 100%">
                <el-table-column>
                    <template slot="header" slot-scope="scope">
                        <el-row>
                            <el-col :span="12">
                                <div>状态</div>
                            </el-col>
                            <el-col :span="12">
                                <el-button size="small" @click="copyColumn(scope.$index, 'status')">复制整列数据</el-button>
                            </el-col>
                        </el-row>
                    </template>
                    <template slot-scope="scope">
                        {{ scope.row.status }}
                    </template>
                </el-table-column>
                <el-table-column>
                    <template slot="header" slot-scope="scope">
                        <el-row>
                            <el-col :span="12">
                                <div>价格</div>
                            </el-col>
                            <el-col :span="12">
                                <el-button size="small" @click="copyColumn(scope.$index, 'price')">复制整列数据</el-button>
                            </el-col>
                        </el-row>
                    </template>
                    <template slot-scope="scope">
                        {{ scope.row.price }}
                    </template>
                </el-table-column>
                <el-table-column label="库存数量">
                    <template slot="header" slot-scope="scope">
                        <el-row>
                            <el-col :span="12">
                                <div>库存</div>
                            </el-col>
                            <el-col :span="12">
                                <el-button size="small"
                                    @click="copyColumn(scope.$index, 'totalStockNum')">复制整列数据</el-button>
                            </el-col>
                        </el-row>
                    </template>
                    <template slot-scope="scope">
                        {{ scope.row.totalStockNum }}
                    </template>
                </el-table-column>
                <el-table-column>
                    <template slot="header" slot-scope="scope">
                        <el-row>
                            <el-col :span="12">
                                <div>备注</div>
                            </el-col>
                            <el-col :span="12">
                                <el-button size="small"
                                    @click="copyColumn(scope.$index, 'statusText')">复制整列数据</el-button>
                            </el-col>
                        </el-row>
                    </template>
                    <template slot-scope="scope">
                        {{ scope.row.statusText }}
                    </template>
                </el-table-column>
            </el-table>

        </el-col>
    </el-row>
</template>

<script>

export default {
    // 组件代码
    name: "ProductStatus",
    data() {
        return {
            loading: false,
            productIdList: "",
            productIdListres: [],
            percentage: 0,
            num: 0,
            acnum:0,
            dialogVisible:false
        }
    },
    methods: {
        copyColumn(index, key) {
            const selectedColumnData = []; 
            for (let i = 0; i < this.productIdListres.length; i++) {
                selectedColumnData.push(this.productIdListres[i][key]);
            }
            const copyText = selectedColumnData.join('\n');
            navigator.clipboard.writeText(copyText).then(() => {
                console.log('复制成功');
            }).catch((error) => {
                console.log('复制失败', error);
            });
        },
        calculateLines() {
            const content = this.productIdList;
            const lines = content.split('\n').length;
            this.num = lines;
        },
        async checkProduct() {
            this.productIdListres = [];
            if (!this.productIdList.length) {
                this.$message.error('商品ID不能为空');
                return
            }
            this.loading = true
            document.title = "开始检测商品"
            this.dialogVisible = true

            const lines = this.productIdList.split('\n');
            let isfall = false;
            for (let index = 0; index < lines.length; index++) {
                const element = lines[index];
                await this.getGoodsDetails(element).then(res => {


                    if(res.data.code == 200){
                        this.productIdListres.push(res.data.data)
                        var regex = new RegExp(element+'\n|\n'+element+'|'+element, 'gm');
                        this.productIdList = this.productIdList.replace(regex,"")
                    }else{
                        this.productIdListres.push({ "status": "下架" });
                    }
                  

                    // this.productIdList = this.productIdList.replace(regex, '');
                    // let lines2 = this.productIdList.split('\n');
                    // const lineToRemove = index

                    // if (lineToRemove >= 0 && lineToRemove < lines2.length) {
                    //     // 移除要删除的行
                    //     lines2.splice(lineToRemove, 1);

                    //     // 重新组合文本内容
                    //     this.productIdList = lines2.join('\n');

                    // } else {
                    //     alert("请输入有效的行号！");
                    // }

                }).catch(err => {
                    this.productIdListres.push({ "status": "下架" });
                })
                

                this.acnum = index+1
                this.percentage = Math.floor((index + 1) / lines.length * 100)
                document.title = "检测商品进度:" + this.percentage + '%'

            }

            this.loading = false
            this.dialogVisible = false
            // this.$message({
            //     message: lines.length+'个商品已经全部读取完毕',
            //     type: 'success'
            // });

            this.$notify({
                title: '执行完毕',
                message: lines.length + '个商品已经全部读取完毕',
                position: 'bottom-left'
            });
            this.productIdList = ""
            this.num = 0

        },

        getGoodsDetails(e) {
            return new Promise((resolve, reject) => {

                const params = {
                    productId: e,
                };
                this.$axios({
                    method: "POST",
                    url: '/api/dy/productSkus',
                    data: params
                }).then(res => {
                    resolve(res)
                    // if (!res) {
                    //     console.log("商品是下架商品");
                    //     resolve()
                    // } else {
                    //     if (res.inStock && res.presellType == 1) {
                    //         console.log("商品是预售商品");
                    //     }

                    //     if (res.inStock && res.presellType == 0) {
                    //         console.log("商品是现货商品");
                    //     }

                    //     if (!res.inStock && res.presellType == 1) {
                    //         console.log("商品是无货");
                    //     }
                    //     resolve()

                    // }

                }).catch(err => {
                    console.log(err);
                    reject()
                })








            })
        }
    }
}
</script>

<style>
/* 样式代码 */
</style>